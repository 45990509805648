<script setup lang="ts">
import { PropType } from 'vue'
import { Form } from 'vee-validate'

const props = defineProps({
  hideTitleOnMobile: {
    type: Boolean,
    default: false,
  },
  hideSignUpOnMobile: {
    type: Boolean,
    default: false,
  },
  module: {
    type: String as PropType<'page' | 'modal'>,
    default: 'page',
  },
})

const { $modal, $auth } = useNuxtApp()
const router = useRouter()

const initialValues = {
  username: '',
  password: '',
}

const handleSuccess = () => {
  if (props.module === 'page') {
    router.push('/')
  } else {
    if ($modal.loginRedirectionPath) {
      router.push($modal.loginRedirectionPath)
    }
    $modal.reset()
  }
}

const handleError = ({ error, setFieldError }) => {
  const { graphQLErrors: errors } = error
  const authenticationError = errors?.some((_error) =>
    ['incorrect_password', 'invalid_username', 'invalid_email'].includes(
      _error.message,
    ),
  )

  if (authenticationError) {
    setFieldError('password', 'Incorrect username/password')
  } else {
    setFieldError('password', errors[0].message)
  }
}

const { form, handleSubmit } = useForm({
  initialValues,
  fetcher: $auth.login,
  onSuccess: handleSuccess,
  onError: handleError,
})
</script>

<template>
  <Form
    v-slot="{ isSubmitting, meta }"
    data-testid="login-form"
    class="fill-max-width max-w-form-container"
    @submit="handleSubmit"
  >
    <h1
      class="mb-10 text-2xl font-bold"
      :class="{ 'hidden md:block': hideTitleOnMobile }"
    >
      Login
    </h1>

    <BaseInput
      v-model="form.username"
      data-testid="username"
      label="Username or Email"
      name="username"
      placeholder="name@email.com"
      rules="required"
      class="mb-5"
      is-focused
      :validate-on-blur="false"
    />

    <BaseInput
      v-model="form.password"
      data-testid="password"
      label="Password"
      name="password"
      type="password"
      rules="required"
      class="mb-3"
    />

    <div class="mb-5 text-right">
      <button
        type="button"
        class="text-sm font-semibold underline"
        @click="$modal.open('forgotPassword')"
      >
        Forgotten password?
      </button>
    </div>

    <div
      class="flex flex-col-reverse items-stretch justify-end gap-x-2 md:flex-row"
    >
      <BaseButton
        variant="text"
        :class="{ 'hidden md:flex': hideSignUpOnMobile }"
        @click="$modal.open('requestATrial')"
      >
        Request a trial
      </BaseButton>
      <BaseButton type="submit" :disabled="!meta.valid" :loading="isSubmitting">
        Login
      </BaseButton>
    </div>
  </Form>
</template>
